body {
	font-family: 'Roboto', sans-serif !important;
}

.form-select-col-2 {
	padding-bottom: 10px;

	>div[class*="css-"] {
		width: 100%;
		padding-right: 10px;
	}
}

.block-info-quick-support {
	h4 {
		margin: 20px 0;
	}
}

.fix-modal-height {
	height: 600px;
}

.input-has-border input {
	border: 1px solid #d8d6de !important;
}

.invalid-feedback2 {
	width: 100%;
	font-size: 1rem;
	color: #ea5455;
	padding-left: 5px;
}

.invalid-feedback2 {
	&.dis-inline {
		display: inline;
	}
}

[dir] .btn-primary {
	border-color: #2d58af !important;
	background-color: #2d58af !important;
}

[dir=ltr] .main-menu.menu-light .navigation>li ul .active,
[dir=ltr] .main-menu.menu-light .navigation>li.active>a {
	background: #2d58af;
}

html .table>tbody {
	background: #fff;
}

.filter-listing {
	margin-bottom: 30px;
}

.filter-listing button {
	margin-right: 15px;
}

.popup-wp .header-navbar.navbar-shadow {
	display: none;
}
.popup-wp .navbar-static.footer-hidden .app-content .content-area-wrapper {
	height: 100vh;
}
.popup-wp .navbar-static .app-content {
	padding: 0px !important;
}